// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-2018-js": () => import("./../src/pages/2018.js" /* webpackChunkName: "component---src-pages-2018-js" */),
  "component---src-pages-2019-js": () => import("./../src/pages/2019.js" /* webpackChunkName: "component---src-pages-2019-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-call-for-speakers-js": () => import("./../src/pages/call-for-speakers.js" /* webpackChunkName: "component---src-pages-call-for-speakers-js" */),
  "component---src-pages-childcare-js": () => import("./../src/pages/childcare.js" /* webpackChunkName: "component---src-pages-childcare-js" */),
  "component---src-pages-coding-challenge-js": () => import("./../src/pages/coding-challenge.js" /* webpackChunkName: "component---src-pages-coding-challenge-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inspire-me-js": () => import("./../src/pages/inspire-me.js" /* webpackChunkName: "component---src-pages-inspire-me-js" */),
  "component---src-pages-static-js": () => import("./../src/pages/static.js" /* webpackChunkName: "component---src-pages-static-js" */),
  "component---src-pages-talks-js": () => import("./../src/pages/talks.js" /* webpackChunkName: "component---src-pages-talks-js" */),
  "component---src-pages-team-js": () => import("./../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-workshops-js": () => import("./../src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */)
}

