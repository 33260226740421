/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const Scroll = require('react-scroll')

const scroller = Scroll.scroller

const scrollTo = id => () => {
  //const el = document.querySelector(id)
  /*if (id) {
    scroller.scrollTo(id, {
      duration: 2500,
      delay: 200,
      smooth: true,
      offset: 150, // Scrolls to element + 50 pixels down the page
    })
  }
  return false
  */
}

export const onRouteUpdate = ({ location: { hash } }) => {
  if (hash) {
    scrollTo(hash.slice(1))
    return false
    //window.setTimeout(scrollTo(hash.slice(1)), 1500)
  }
}
